@import url('/src/assets/font/inter.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #0a0c11;
}

.bf-gradient:before {
    position: absolute;
    padding: 1.5px;
    background: linear-gradient(to bottom right,rgba(5,45,255,.6),rgba(62,243,255,.6));
    border-radius: inherit;
    content: "";
    inset: 0;
    -webkit-mask: linear-gradient(#000,#000) content-box,linear-gradient(#000,#000);
    mask: linear-gradient(#000,#000) content-box,linear-gradient(#000,#000);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.hide-sb::-webkit-scrollbar {
    display: none;
}

.hide-sb {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.map-tiles {
    filter: invert(100%) hue-rotate(190deg) brightness(70%) contrast(90%);
}


.mapboxgl-popup {
    min-width: 370px !important;
    max-width: 370px !important;
    width: 370px !important;
}

input[type='range'] {
    border-radius: 8px;
    height: 4px;
    width: 150px;
    outline: none;
    background: #131821;
    -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
    width: 6px;
    -webkit-appearance: none;
    height: 16px;
    background: #00BFFF;
    border-radius: 5px;
}

input:checked + div {
    border-color: #00BFFF;
}
input:checked + div svg {
    @apply block;
}

/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/assets/font/satoshi
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Variable(Variable font)
 * Satoshi Variable Italic(Variable font)
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
    font-family: 'Satoshi-Variable';
    src: url('./assets/font/Satoshi-Variable.woff2') format('woff2'),
    url('./assets/font/Satoshi-Variable.woff') format('woff'),
    url('./assets/font/Satoshi-Variable.ttf') format('truetype');
    font-weight: 300 900;
    font-display: swap;
    font-style: normal;
}


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
    font-family: 'Satoshi-VariableItalic';
    src: url('./assets/font/Satoshi-VariableItalic.woff2') format('woff2'),
    url('./assets/font/Satoshi-VariableItalic.woff') format('woff'),
    url('./assets/font/Satoshi-VariableItalic.ttf') format('truetype');
    font-weight: 300 900;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi-Light';
    src: url('./assets/font/Satoshi-Light.woff2') format('woff2'),
    url('./assets/font/Satoshi-Light.woff') format('woff'),
    url('./assets/font/Satoshi-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-LightItalic';
    src: url('./assets/font/Satoshi-LightItalic.woff2') format('woff2'),
    url('./assets/font/Satoshi-LightItalic.woff') format('woff'),
    url('./assets/font/Satoshi-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi-Regular';
    src: url('./assets/font/Satoshi-Regular.woff2') format('woff2'),
    url('./assets/font/Satoshi-Regular.woff') format('woff'),
    url('./assets/font/Satoshi-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-Italic';
    src: url('./assets/font/Satoshi-Italic.woff2') format('woff2'),
    url('./assets/font/Satoshi-Italic.woff') format('woff'),
    url('./assets/font/Satoshi-Italic.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi-Medium';
    src: url('./assets/font/Satoshi-Medium.woff2') format('woff2'),
    url('./assets/font/Satoshi-Medium.woff') format('woff'),
    url('./assets/font/Satoshi-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-MediumItalic';
    src: url('./assets/font/Satoshi-MediumItalic.woff2') format('woff2'),
    url('./assets/font/Satoshi-MediumItalic.woff') format('woff'),
    url('./assets/font/Satoshi-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi-Bold';
    src: url('./assets/font/Satoshi-Bold.woff2') format('woff2'),
    url('./assets/font/Satoshi-Bold.woff') format('woff'),
    url('./assets/font/Satoshi-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-BoldItalic';
    src: url('./assets/font/Satoshi-BoldItalic.woff2') format('woff2'),
    url('./assets/font/Satoshi-BoldItalic.woff') format('woff'),
    url('./assets/font/Satoshi-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi-Black';
    src: url('./assets/font/Satoshi-Black.woff2') format('woff2'),
    url('./assets/font/Satoshi-Black.woff') format('woff'),
    url('./assets/font/Satoshi-Black.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-BlackItalic';
    src: url('./assets/font/Satoshi-BlackItalic.woff2') format('woff2'),
    url('./assets/font/Satoshi-BlackItalic.woff') format('woff'),
    url('./assets/font/Satoshi-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: italic;
}


.maplibregl-ctrl-zoom-out, .maplibregl-ctrl-compass {
    border-top: 1px solid #131821 !important;
}

.maplibregl-ctrl-zoom-in > .maplibregl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%270%200%2020%2020%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%0A%20%20%3Cpath%20style%3D%27fill%3A%23a4a4a4%3B%27%20d%3D%27M%2010%206%20C%209.446%206%209%206.4459904%209%207%20L%209%209%20L%207%209%20C%206.446%209%206%209.446%206%2010%20C%206%2010.554%206.446%2011%207%2011%20L%209%2011%20L%209%2013%20C%209%2013.55401%209.446%2014%2010%2014%20C%2010.554%2014%2011%2013.55401%2011%2013%20L%2011%2011%20L%2013%2011%20C%2013.554%2011%2014%2010.554%2014%2010%20C%2014%209.446%2013.554%209%2013%209%20L%2011%209%20L%2011%207%20C%2011%206.4459904%2010.554%206%2010%206%20z%27%20%2F%3E%0A%3C%2Fsvg%3E%0A") !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon, .maplibregl-ctrl button.maplibregl-ctrl-zoom-out .maplibregl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%270%200%2020%2020%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%0A%20%20%3Cpath%20style%3D%27fill%3A%23a4a4a4%3B%27%20d%3D%27m%207%2C9%20c%20-0.554%2C0%20-1%2C0.446%20-1%2C1%200%2C0.554%200.446%2C1%201%2C1%20l%206%2C0%20c%200.554%2C0%201%2C-0.446%201%2C-1%200%2C-0.554%20-0.446%2C-1%20-1%2C-1%20z%27%20%2F%3E%0A%3C%2Fsvg%3E%0A") !important;
}

.margin-top > .map> .maplibregl-control-container > .maplibregl-ctrl-top-right {
    margin-top: 35px;
}

.margin-top-app > .map > .maplibregl-control-container > .maplibregl-ctrl-top-right {
    margin-top: 50px;
}

.margin-bottom-app > .map > .maplibregl-control-container > .mapboxgl-ctrl-bottom-right {
    margin-bottom: 65px;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon, .maplibregl-ctrl button.maplibregl-ctrl-compass .maplibregl-ctrl-icon {
    background-size: 18px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2020%2020%27%3E%0A%09%3Cpolygon%20fill%3D%27%23a4a4a4%27%20points%3D%276%2C9%2010%2C1%2014%2C9%27%2F%3E%0A%09%3Cpolygon%20fill%3D%27%23545454%27%20points%3D%276%2C11%2010%2C19%2014%2C11%20%27%2F%3E%0A%3C%2Fsvg%3E") !important;
}

.maplibregl-ctrl-group {
    background-color: #0a0c11 !important;
}

.maplibregl-ctrl-attrib {
    border-radius: 6px 0 0 0;
}

.maplibregl-popup-content {
    background-color: #0a0c11 !important;
    padding: 12px 20px 15px 20px !important;
}

.maplibregl-popup-anchor-bottom > .maplibregl-popup-tip {
    border-top-color: #0a0c11 !important;
}

.maplibregl-popup-anchor-top-right > .maplibregl-popup-tip {
    border-bottom-color: #0a0c11 !important;
}

.maplibregl-popup-anchor-top-left > .maplibregl-popup-tip {
    border-bottom-color: #0a0c11 !important;
}

.maplibregl-popup-anchor-bottom-left > .maplibregl-popup-tip {
    border-top-color: #0a0c11 !important;
}

.maplibregl-popup-anchor-bottom-right > .maplibregl-popup-tip {
    border-top-color: #0a0c11 !important;
}

.maplibregl-popup-anchor-top > .maplibregl-popup-tip {
    border-bottom-color: #0a0c11 !important;
}

.maplibregl-popup-anchor-right > .maplibregl-popup-tip {
    border-left-color: #0a0c11 !important;
}

.maplibregl-popup-anchor-left > .maplibregl-popup-tip {
    border-right-color: #0a0c11 !important;
}

.maplibregl-popup-close-button {
    padding: 6px;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-content, .maplibregl-popup-anchor-top-left .maplibregl-popup-content {
    border-radius: 0 10px 10px 10px !important;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-content, .maplibregl-popup-anchor-top-right .maplibregl-popup-content {
    border-radius: 10px 0 10px 10px !important;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content, .maplibregl-popup-anchor-bottom-left .maplibregl-popup-content {
    border-radius: 10px 10px 10px 0 !important;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content, .maplibregl-popup-anchor-bottom-right .maplibregl-popup-content {
    border-radius: 10px 10px 0 10px !important;
}

.mapboxgl-popup-anchor-bottom  .mapboxgl-popup-content, .mapboxgl-popup-anchor-top  .mapboxgl-popup-content, .mapboxgl-popup-anchor-right  .mapboxgl-popup-content, .mapboxgl-popup-anchor-left  .mapboxgl-popup-content {
    border-radius: 10px 10px 10px 10px !important;
}